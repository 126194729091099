html {
    width: 100vw;
    overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Kumbh Sans', sans-serif;              
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.ql-snow .ql-tooltip {
	z-index: 999;
}